import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { DropdownSelect, IDropdownList } from '@app/components/formComponents/dropdownSelect'
import { RootState } from '@app/store/configureStore'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ProfileStatus, TypeOfRole } from '../profileList/profileEnums'
import { classNames, range } from '../utils'
import Input from '@app/components/formComponents/input'
import {
  EmailStatus,
  RespondentProfileStatus,
  SmsStatus,
} from '../profileList/editProfile/interface'
import { Spinner } from '@app/components/spinner/spinner'
import { ApiEndpoints, Profile, Respondent, useApiEndpoints } from '@app/api/end-points'
import { getParticipantFormInfo } from './actions'
import useParticipantProfileAuth from './useParticipantProfileAuth'
import {
  FormQuestions,
  IFormFilledDataKindOfButNotReally,
  ProfileFormType,
} from '@app/components/selfForm/selfForm'
import { IProfileFormInfoResponse } from '@app/commonUtils/participantProfileHelper'

type TranslateFn = (key: string) => string

type CurrentStepPanelProps = React.PropsWithChildren<{
  __t: TranslateFn
  header: React.ReactNode
  previousDisabled?: boolean
  nextDisabled?: boolean
  onPreviousClick?: (event: React.MouseEvent) => unknown
  onNextClick?: (event: React.MouseEvent) => unknown
}>

const CurrentStepPanel: React.FC<CurrentStepPanelProps> = (props) => {
  const __t = props.__t

  return (
    <div className='bg-white border rounded mb-4'>
      {props.header && (
        <div className='p-4 fs-5 fw-bold border-bottom'>
          <i className='bi bi-exclamation-circle-fill me-2' />
          {props.header}
        </div>
      )}
      {props.children}
      <div className='d-flex bg-success bg-opacity-10 rounded-bottom p-4'>
        {props.onPreviousClick && (
          <button className='btn' disabled={props.previousDisabled} onClick={props.onPreviousClick}>
            <i className='bi bi-arrow-left me-2' />
            {__t('Go back')}
          </button>
        )}
        <div className='flex-grow-1' />
        {props.onNextClick && (
          <button
            className='btn btn-success'
            disabled={props.nextDisabled}
            onClick={props.onNextClick}
          >
            {__t('Continue')}
            <i className='bi bi-arrow-right me-2' />
          </button>
        )}
      </div>
    </div>
  )
}

type UpcomingStepPanelProps = React.PropsWithChildren<unknown>

const UpcomingStepPanel: React.FC<UpcomingStepPanelProps> = (props) => {
  return (
    <div className='bg-secondary bg-opacity-10 rounded p-4 mb-2 fw-bold'>
      <i className='bi bi-circle-fill opacity-25 me-2' />
      {props.children}
    </div>
  )
}

type CompletedStepPanelProps = React.PropsWithChildren<unknown>

const CompletedStepPanel: React.FC<CompletedStepPanelProps> = (props) => {
  return (
    <div className='bg-white rounded p-4 mb-2'>
      <i className='bi bi-circle-fill text-success me-2' />
      {props.children}
    </div>
  )
}

type SetStateFn<S> = React.Dispatch<React.SetStateAction<S>>
enum StepIndex {
  RoleAndRespondents,
  SelfAssessment,
  ProfileStatus,
}
type StepStatus = 'completed' | 'current' | 'upcoming'
type StepComponentProps = {
  __t: TranslateFn
  languageCode: string
  api: ApiEndpoints
  status: StepStatus
  state: Profile
  setState: SetStateFn<Profile>
  move: SetStateFn<StepIndex>
}
type StepComponent = React.FC<StepComponentProps>
type StepDefinition = {
  index: StepIndex
  component: StepComponent
}

function getShortName(name: string): string {
  const parts = name.split(/\s+/)
  const first = parts[0]
  const last = parts[1]?.substring(0, 1)
  return [first, last].filter((x) => !!x).join(' ')
}

const RoleAndRespondentsStep: StepComponent = (props) => {
  const __t = props.__t

  const RESPONDENT_COUNT_OPTIONS: Array<IDropdownList> = range(1, MAX_RESPONDENTS + 1).map(
    (x, i) => {
      return {
        id: i,
        displayName: (i + 1).toString(),
        value: (i + 1).toString(),
      }
    }
  )

  const RESPONDENT_ROLE_NAMES: { [K in TypeOfRole]: string } = {
    [TypeOfRole.Colleagues]: __t('Colleagues'),
    [TypeOfRole.TeamMembers]: __t('Team members'),
    [TypeOfRole.Clients]: __t('Clients'),
    [TypeOfRole.Suppliers]: __t('Suppliers'),
    [TypeOfRole.Subordinates]: __t('Subordinates'),
  }

  const RESPONDENT_ROLE_OPTIONS: Array<IDropdownList> = [
    {
      id: 1,
      displayName: RESPONDENT_ROLE_NAMES[TypeOfRole.Colleagues],
      value: TypeOfRole.Colleagues.toString(),
    },
    {
      id: 2,
      displayName: RESPONDENT_ROLE_NAMES[TypeOfRole.TeamMembers],
      value: TypeOfRole.TeamMembers.toString(),
    },
    {
      id: 3,
      displayName: RESPONDENT_ROLE_NAMES[TypeOfRole.Clients],
      value: TypeOfRole.Clients.toString(),
    },
    {
      id: 4,
      displayName: RESPONDENT_ROLE_NAMES[TypeOfRole.Suppliers],
      value: TypeOfRole.Suppliers.toString(),
    },
    {
      id: 5,
      displayName: RESPONDENT_ROLE_NAMES[TypeOfRole.Subordinates],
      value: TypeOfRole.Subordinates.toString(),
    },
  ]

  function createRespondentChangeHandler(index: number, field: keyof Respondent) {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      props.setState((prev) => {
        const respondents = prev.respondents.slice()
        respondents[index] = {
          ...respondents[index],
          [field]: event.target.value,
        }
        return {
          ...prev,
          respondents: respondents,
        }
      })
    }
  }

  switch (props.status) {
    case 'completed': {
      const names = props.state.respondents.map((respondent) => {
        return getShortName(respondent.name)
      })

      return (
        <CompletedStepPanel>
          {__t('My respondents are')}&nbsp;
          <span className='text-decoration-underline'>
            {props.state.noOfRespondents}&nbsp;
            {RESPONDENT_ROLE_NAMES[props.state.roleId]}
          </span>
          {names.map((name, i) => {
            return (
              <span key={i} className='ms-2 py-1 px-2 bg-dark bg-opacity-10 rounded'>
                {name}
              </span>
            )
          })}
        </CompletedStepPanel>
      )
    }
    case 'current': {
      const isFormSomewhatValid = props.state.respondents.every((r) => r.name && r.email)

      return (
        <CurrentStepPanel
          __t={__t}
          header={__t('My respondents')}
          onNextClick={(event) => {
            event.preventDefault()
            props.api
              .updateProfile(props.state.id!, props.state)
              .then(() => props.move(StepIndex.SelfAssessment))
          }}
          nextDisabled={!isFormSomewhatValid}
        >
          <div className='p-4 border-bottom'>
            <p>{__t('I want to know how Im perceived by certain')}</p>
            <div className='row'>
              <div className='col-md-2 col-6'>
                <DropdownSelect
                  name='big_useless_name'
                  list={RESPONDENT_COUNT_OPTIONS}
                  handleDropdownSelect={(item) => {
                    props.setState({
                      ...props.state,
                      noOfRespondents: Number(item.value),
                    })
                  }}
                  value={String(props.state.noOfRespondents)}
                />
              </div>
              <div className='col-md-3 col-6'>
                <DropdownSelect
                  name='big_useless_name'
                  list={RESPONDENT_ROLE_OPTIONS}
                  handleDropdownSelect={(item) => {
                    props.setState({
                      ...props.state,
                      roleId: Number(item.value),
                    })
                  }}
                  value={String(props.state.roleId)}
                />
              </div>
            </div>
          </div>

          <div className='p-4'>
            <p className='fw-bold'>{__t('Choose your respondents')}</p>
            <p>
              {__t(
                'Invite the respondents you want to engage to create your IDI profile. Enter their contact details below to send them instructions.'
              )}
            </p>
            {props.state.respondents.map((respondent, k) => {
              const clazz = classNames({
                'mt-4 pb-4': true,
                'border-bottom': k < props.state.noOfRespondents - 1,
              })

              return (
                <div key={k} className={clazz}>
                  <div className='row'>
                    <div className='col-12 col-md-4 mt-2 mt-md-0'>
                      <Input
                        placeholder={__t('Name')}
                        value={respondent.name}
                        narrow={true}
                        required={true}
                        handleInputChange={createRespondentChangeHandler(k, 'name')}
                      />
                    </div>
                    <div className='col-12 col-md-4 mt-2 mt-md-0'>
                      <Input
                        placeholder={__t('Email')}
                        value={respondent.email}
                        narrow={true}
                        required={true}
                        handleInputChange={createRespondentChangeHandler(k, 'email')}
                      />
                    </div>
                    <div className='col-12 col-md-4 mt-2 mt-md-0'>
                      <Input
                        placeholder={__t('Mobile number')}
                        value={respondent.telephone}
                        narrow={true}
                        handleInputChange={createRespondentChangeHandler(k, 'telephone')}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </CurrentStepPanel>
      )
    }
    case 'upcoming':
      throw new Error('Bad.')
  }
}

const SelfAssessmentStep: StepComponent = (props) => {
  const __t = props.__t

  // TODO: maybe move this state up to the root? hm...
  const [questions, setQuestions] = React.useState<
    ReadonlyArray<IFormFilledDataKindOfButNotReally>
  >([])

  React.useEffect(() => {
    props.api
      .getParticipantOrRespondentWords({
        profileId: props.state.id!,

        // TODO: respondent support!
        profileRespondentId: 0,
        languageCode: props.languageCode,
      })
      .then(setQuestions)
  }, [])

  function setQuestionValue(wordPairId: number, value: number) {
    const index = questions.findIndex((x) => x.id === wordPairId)
    const next = questions.slice()
    next[index].value = value
    setQuestions(next)
  }

  const isFormCompleted =
    questions.length > 0 && questions.every((q) => q.value !== null && q.value !== 0)

  switch (props.status) {
    case 'completed':
      return <CompletedStepPanel>{__t('Self assessment')}</CompletedStepPanel>
    case 'current':
      return (
        <CurrentStepPanel
          __t={__t}
          header={__t('Self assessment')}
          onPreviousClick={(event) => {
            event.preventDefault()
            props.move(StepIndex.RoleAndRespondents)
          }}
          nextDisabled={!isFormCompleted}
          onNextClick={(event) => {
            event.preventDefault()

            props.api
              .fillParticipantProfileSelfForm({
                profileId: props.state.id!,
                languageCode: props.languageCode,
              })
              .then(() => {
                props.move(StepIndex.ProfileStatus)
              })
          }}
        >
          <FormQuestions
            questions={questions}
            isError={false}
            onAnswered={(wordPairId, value) => {
              setQuestionValue(wordPairId, value)

              props.api
                .updateParticipantFormFilledDataAnswer({
                  profileId: props.state.id!,
                  profileRespondentId: 0,
                  formType: ProfileFormType.Self,
                  wordPairId: wordPairId,
                  value: value,
                })
                .catch((err) => {
                  setQuestionValue(wordPairId, 0)
                  return Promise.reject(err)
                })
            }}
          />
        </CurrentStepPanel>
      )
    case 'upcoming':
      return <UpcomingStepPanel>{__t('Self assessment')}</UpcomingStepPanel>
  }
}

const ProfileStatusStep: StepComponent = (props) => {
  const __t = props.__t

  switch (props.status) {
    case 'completed':
      return <CompletedStepPanel>{__t('IDI Profile')}</CompletedStepPanel>
    case 'current': {
      const invited = props.state.respondents.filter(
        (r) => r.status === RespondentProfileStatus.Invite
      )
      const completed = props.state.respondents.filter(
        (r) => r.status === RespondentProfileStatus.Completed
      )

      return (
        <CurrentStepPanel
          __t={__t}
          header={__t('IDI Profile')}
          onPreviousClick={(event) => {
            event.preventDefault()
            props.move(StepIndex.SelfAssessment)
          }}
        >
          <div className='p-4'>
            {__t('Your profile is ready when your respondents have responded')}.
            {invited.length !== 0 && (
              <>
                <div className='mt-4'>{__t('Waiting for response from')}:</div>
                {invited.map((r, index) => {
                  return (
                    <div className='row rounded bg-secondary bg-opacity-10 mt-2 mx-0' key={index}>
                      <div className='d-flex align-items-center p-3 fs-5 col-12 col-md-5 fw-bold'>
                        <i className='bi bi-clock me-3' />
                        {getShortName(r.name)}
                      </div>
                    </div>
                  )
                })}
              </>
            )}
            {completed.length !== 0 && (
              <>
                <div className='mt-4'>{__t('Completed')}:</div>
                {completed.map((r, index) => {
                  return (
                    <div className='row rounded bg-success bg-opacity-10 mt-2 mx-0' key={index}>
                      <div className='d-flex align-items-center p-3 fs-5 col-12 col-md-5 fw-bold'>
                        <i className='bi bi-check-circle-fill text-success me-3"' />
                        {getShortName(r.name)}
                      </div>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        </CurrentStepPanel>
      )
    }
    case 'upcoming':
      return <UpcomingStepPanel>{__t('IDI Profile')}</UpcomingStepPanel>
  }
}

const STEP_DEFINITIONS: ReadonlyArray<StepDefinition> = [
  {
    index: StepIndex.RoleAndRespondents,
    component: RoleAndRespondentsStep,
  },
  {
    index: StepIndex.SelfAssessment,
    component: SelfAssessmentStep,
  },
  {
    index: StepIndex.ProfileStatus,
    component: ProfileStatusStep,
  },
]

const NUM_RESPONDENTS_DEFAULT = 3
const MAX_RESPONDENTS = 20
const EMPTY_RESPONDENT: Respondent = {
  name: '',
  email: '',
  telephone: '',
  status: RespondentProfileStatus.Invite,
}

type AsyncState<T> = { kind: 'none' } | { kind: 'loading' } | { kind: 'ok'; value: T }

function tryUnwrapAsyncState<T>(state: AsyncState<T>): T | undefined {
  return state.kind === 'ok' ? state.value : undefined
}

function createUnwrappedAsyncSetter<T extends object>(
  setOuterState: SetStateFn<AsyncState<T>>
): SetStateFn<T> {
  return (value) => {
    setOuterState((prevState) => {
      let next: T
      const unwrappedPrevious = tryUnwrapAsyncState(prevState)
      if (typeof value === 'function') {
        if (!unwrappedPrevious) {
          throw new Error(
            "Attempted to set async state with a callback, but no previous 'ok' state was set."
          )
        }
        next = value(unwrappedPrevious)
      } else {
        next = value
      }
      return {
        kind: 'ok',
        value: {
          ...unwrappedPrevious,
          ...next,
        },
      }
    })
  }
}

function deriveCurrentStepIndex(profile: Profile): StepIndex {
  // 'active' seems to mean 'self form filled'.
  if (profile.status === ProfileStatus.Active) {
    return StepIndex.ProfileStatus
  }

  const didCreateRespondents =
    !!profile.roleId &&
    profile.respondents.length > 0 &&
    profile.respondents.every((r) => r.name && r.email)

  if (didCreateRespondents) {
    return StepIndex.SelfAssessment
  }

  return StepIndex.RoleAndRespondents
}

export const CreateProfileV2: React.FC<unknown> = (props) => {
  const dispatch = useDispatch()
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const __t = getLanguageValue.bind(undefined, languageText)
  const [state, setState] = React.useState<AsyncState<Profile>>({ kind: 'none' })
  const [currentStepIndex, setCurrentStepIndex] = React.useState<StepIndex>(0)
  const profile = tryUnwrapAsyncState(state)
  const setProfile = createUnwrappedAsyncSetter(setState)
  const api = useApiEndpoints(dispatch)
  const { uniqueCode } = useParticipantProfileAuth()
  const userLanguage = useSelector(
    (state: RootState) => state.loginReducer.userLanguage.userLanguageCode
  )

  React.useEffect(() => {
    if (state.kind !== 'none') {
      return
    }
    if (!uniqueCode) {
      return
    }

    getParticipantFormInfo(uniqueCode, dispatch).then((info) => {
      api.getProfile(info.profileId).then((profile) => {
        setProfile(profile)
        setCurrentStepIndex(deriveCurrentStepIndex(profile))
      })
    })
  }, [state.kind])

  React.useEffect(() => {
    if (!profile) {
      return
    }

    // let's be nice to the user when changing the 'noOfRespondents'
    // and keep as much data as we can, even if there are gaps in
    // the table.
    const next = profile.respondents
      .filter((x) => x.name || x.email || x.telephone)
      .slice(0, profile.noOfRespondents)

    for (let i = next.length; i < profile.noOfRespondents; ++i) {
      next.push({ ...EMPTY_RESPONDENT })
    }

    setProfile((prev) => {
      return {
        ...prev,
        respondents: next,
      }
    })
  }, [profile?.noOfRespondents])

  if (state.kind !== 'ok') {
    return (
      <div className='mt-5 mb-5 col-md-10 mx-auto'>
        <Spinner />
      </div>
    )
  }

  return (
    <div className='mt-5 mb-5 col-md-10 mx-auto'>
      <h3 className='pb-1'>{__t('Create new profile')}</h3>
      <div className='pb-4'>
        {__t(
          'With IDI you get valuable information about how you act in a role that is important to you'
        )}
      </div>
      {STEP_DEFINITIONS.map((defn) => {
        const status: StepStatus =
          defn.index === currentStepIndex
            ? 'current'
            : defn.index < currentStepIndex
              ? 'completed'
              : 'upcoming'
        const Component = defn.component

        return (
          <Component
            key={defn.index}
            api={api}
            status={status}
            state={state.value}
            setState={setProfile}
            move={setCurrentStepIndex}
            __t={__t}
            languageCode={userLanguage}
          />
        )
      })}
    </div>
  )
}
