import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { RootState } from '@app/store/configureStore'
import { getLanguageValue } from '../../../commonUtils/languageFunctionsHelper'

export interface IParticipantHeaderData {
  id: number
  title: string
}

interface IParticipantHeaderComponentProps {
  currentHeaderId: number
}

const ParticipantHeaderComponent = (props: IParticipantHeaderComponentProps) => {
  const { currentHeaderId } = props

  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)

  const participantHeaderData = useMemo(
    () =>
      [
        {
          id: 1,
          title: getLanguageValue(languageText, 'Role'),
        },
        {
          id: 2,
          title: getLanguageValue(languageText, 'Self assessment'),
        },
        {
          id: 3,
          title: getLanguageValue(languageText, 'Respondents'),
        },
        {
          id: 4,
          title: getLanguageValue(languageText, 'Profile'),
        },
        {
          id: 5,
          title: getLanguageValue(languageText, 'Academy'),
        },
      ] as IParticipantHeaderData[],
    [languageText]
  )

  const getTitleClass = (id: number): string => {
    let updatedClass: string = ''
    if (id === currentHeaderId)
      updatedClass = 'fw-bold text-success border-bottom border-secondary-subtle'
    else if (id < currentHeaderId) updatedClass = 'fw-bold text-success opacity-50'
    else updatedClass = 'text-body-tertiary'
    return updatedClass
  }

  return (
    <>
      <div className='d-flex justify-content-md-start gap-2 flex-wrap'>
        {participantHeaderData.map((item: IParticipantHeaderData) => (
          <div
            key={item.id}
            className={clsx('d-flex align-items-center gap-1 me-md-4', getTitleClass(item.id))}
          >
            <span className=' fs-5'>{item.id}.</span>
            <span className={`fs-5 ${item.id !== currentHeaderId && 'd-md-block d-none'}`}>
              {item.title}
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

export default ParticipantHeaderComponent
