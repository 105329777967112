import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import clsx from 'clsx'

import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'
import { RootState } from '@app/store/configureStore'
import { routePath } from '../routePaths'
import { RoleName, Roles } from '../commonEnums'
import Logo from '../../../public/DeliveryImages/IDI_Logotyp.webp'
import {
  getGuideData,
  setCurrentUserLanguages,
  setSpinner,
  triggerLanguageChange,
} from '../actions'
import {
  getFacilitatorLanguages,
  getParticipantLanguages,
} from '../languageList/languageTexts/actions'
import { updateUserLanguage } from '../usersProfile/edit-user/actions'
import {
  getLoggedInUserData,
  getUserDetails,
  logout,
  setDefaultClient,
} from '../auth/signUp/actions'
import {
  canParticipantChangeLanguageWithoutEffect,
  clearParticipantProfileDetails,
  updateParticipantLanguage,
} from '../participantPages/actions'
import { CustomModal } from '@app/components/modals/customModal'
import { KeyCode } from '@app/components/formComponents/input'
import { IClients, ILanguage, IUpdateLanguageParams } from '../commonInterfaces'
import {
  isLoggedInRoleAdmin,
  isLoggedInRoleFacilitator,
  isLoggedInRoleParticipant,
} from '@app/commonUtils/roleHelper'
import { resetState } from '@app/reducers/actions'
import { langKey } from '@app/consts'
import { uniqueBy } from '../utils'
import { getAllClientsByUser } from '../usersProfile/account/actions'
import { IGetAllClientsByUserBody } from '../usersProfile/account/hooks'
import { switchUserRole } from '../twoFactorAuthenticationPage/actions'
import { isLoggedIn } from '../rootState'
import { MultiRoleBehavior, useAuthFlow } from '@app/auth'

interface IMenu {
  icon: string
  name: string
  path: string
}

const NavigationMenu = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loggedInUserRole = useSelector((state: RootState) => state.loginReducer.loggedInUserRole)
  const userLanguage = useSelector((state: RootState) => state.loginReducer.userLanguage)
  const userDetails = useSelector((state: RootState) => state.loginReducer.userDetails)
  const userRoles: string[] = userDetails?.userRoles.map((a) => a.assignedRoleName) ?? []
  const defaultClient = useSelector((state: RootState) => state.loginReducer.defaultClient)
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const currentUserLanguages = useSelector(
    (state: RootState) => state.mainReducer.currentUserLanguages
  )
  const isLanguageChanged = useSelector((state: RootState) => state.mainReducer.isLanguageChanged)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const isAdmin = isLoggedInRoleAdmin(loggedInUserRole)
  const isFacilitator = isLoggedInRoleFacilitator(loggedInUserRole)
  const isParticipant = isLoggedInRoleParticipant(loggedInUserRole)

  const [openLeftMenu, setOpenLeftMenu] = useState<boolean>(false)
  const [openRightMenu, setOpenRightMenu] = useState<boolean>(false)
  const [openAccountList, setOpenAccountList] = useState<boolean>(false)
  const [acccountSearchText, setAccountSearchText] = useState<string>('')
  const [openLanguageList, setOpenLanguageList] = useState<boolean>(false)
  const [selectedLanguage, setSelectedLanguage] = useState<string>('')
  const [languageList, setLanguageList] = useState<ILanguage[]>([])
  const [languageSearchText, setLanguageSearchText] = useState<string>('')
  const [participantsLanguageToSelect, setParticipantsLanguageToSelect] = useState<ILanguage>()
  const [accounts, setAccounts] = React.useState<ReadonlyArray<IClients>>([])
  const authFlow = useAuthFlow(dispatch, navigate)

  React.useEffect(() => {
    if (!openAccountList) {
      return
    }
    const body: IGetAllClientsByUserBody = {
      userId: userId,
      roleName: loggedInUserRole || '',
      filter: '',
      sorting: '',
      maxResultCount: 1_000,
      skipCount: 0,
    }
    getAllClientsByUser(body, dispatch).then((res) => {
      setAccounts(res.items)
    })
  }, [openAccountList])

  const adminLeftMenuList: IMenu[] = [
    {
      icon: 'bi-speedometer2',
      name: 'Dashboard',
      path: routePath.home,
    },
    {
      icon: 'bi-folder',
      name: 'Activities',
      path: routePath.activities,
    },
    {
      icon: 'bi-border-outer',
      name: 'Profiles',
      path: routePath.profiles,
    },
    {
      icon: 'bi-building',
      name: 'Accounts',
      path: routePath.allaccounts,
    },
    {
      icon: 'bi-people-fill',
      name: 'Participants',
      path: routePath.allparticipants,
    },
    {
      icon: 'bi-person-video3',
      name: 'Facilitators',
      path: routePath.allfacilitators,
    },
    {
      icon: 'bi-book',
      name: 'Academy',
      path: routePath.coursesList,
    },
    {
      icon: 'bi-info-circle',
      name: 'Information',
      path: routePath.information,
    },
  ]
  const adminLeftMenuSettingsList: IMenu[] = [
    {
      icon: 'bi-newspaper',
      name: 'News',
      path: routePath.adminNewsList,
    },
    {
      icon: 'bi-file-earmark',
      name: 'Documents',
      path: routePath.adminDocumentList,
    },
    {
      icon: 'bi-person-gear',
      name: 'Admins',
      path: routePath.adminusers,
    },
    {
      icon: 'bi-easel',
      name: 'Presentation',
      path: routePath.presentationTemplateList,
    },
    {
      icon: 'bi-tags',
      name: 'Subscriptions',
      path: routePath.subscriptions,
    },
    {
      icon: 'bi-translate',
      name: 'Languages',
      path: routePath.languages,
    },
    {
      icon: 'bi-translate',
      name: 'Translations',
      path: routePath.translations,
    },
    {
      icon: 'bi-arrows-expand-vertical',
      name: 'Placements',
      path: routePath.placement,
    },
    {
      icon: 'bi-code-square',
      name: 'All Words',
      path: routePath.allWords,
    },
    {
      icon: 'bi-ui-radios-grid',
      name: 'Profile Texts',
      path: routePath.adminProfileDescriptions,
    },
    {
      icon: 'bi-search',
      name: 'AuditLogs',
      path: routePath.auditLogs,
    },
    {
      icon: 'bi-envelope',
      name: 'Emails',
      path: routePath.adminEmailTemplates,
    },
    {
      icon: 'bi-list-task',
      name: 'Logs',
      path: routePath.adminLog,
    },
    {
      icon: 'bi-exclamation-triangle-fill',
      name: 'Alerts',
      path: routePath.adminAlerts,
    },
    {
      icon: 'bi-easel',
      name: 'Upcoming presentations',
      path: routePath.upcomingPresentations,
    },
    {
      icon: 'bi-receipt',
      name: 'Invoices',
      path: routePath.adminInvoices,
    },
    {
      icon: 'bi-receipt',
      name: 'Consents',
      path: routePath.adminConsents,
    },
    {
      icon: 'bi-receipt',
      name: 'Fortnox setup',
      path: routePath.fortnoxSetup,
    },
  ]
  const facilitatorLeftMenuList: IMenu[] = [
    {
      icon: 'bi-speedometer2',
      name: 'Status',
      path: routePath.home,
    },
    {
      icon: 'bi-people',
      name: 'Users',
      path: routePath.employeeList,
    },
    {
      icon: 'bi-folder',
      name: 'Activities',
      path: routePath.activities,
    },
    {
      icon: 'bi-border-outer',
      name: 'Profiles',
      path: routePath.profiles,
    },
    {
      icon: 'bi-info-circle',
      name: 'Information',
      path: routePath.information,
    },
    {
      icon: 'bi-building',
      name: 'Accounts',
      path: routePath.account,
    },
    {
      icon: 'bi-easel',
      name: 'Presentation',
      path: routePath.presentationTemplateList,
    },
  ]

  const adminTopMenuList: IMenu[] = [
    {
      icon: 'bi-folder',
      name: 'Activities',
      path: routePath.activities,
    },
    {
      icon: 'bi-border-outer',
      name: 'Profiles',
      path: routePath.profiles,
    },
    {
      icon: 'bi-building',
      name: 'Accounts',
      path: routePath.allaccounts,
    },
    {
      icon: 'bi-people-fill',
      name: 'Participants',
      path: routePath.allparticipants,
    },
    {
      icon: 'bi-person-video3',
      name: 'Facilitators',
      path: routePath.allfacilitators,
    },
  ]
  const facilitatorTopMenuList: IMenu[] = [
    {
      icon: 'bi-speedometer2',
      name: 'Status',
      path: routePath.home,
    },
    {
      icon: 'bi-people',
      name: 'Users',
      path: routePath.employeeList,
    },
    {
      icon: 'bi-folder',
      name: 'Activities',
      path: routePath.activities,
    },
    {
      icon: 'bi-border-outer',
      name: 'Profiles',
      path: routePath.profiles,
    },
  ]
  const participantMenuList: IMenu[] = [
    {
      icon: 'bi-speedometer',
      name: 'Progress',
      path: routePath.participantDashboard,
    },
    {
      icon: 'bi-border-outer',
      name: 'Profiles',
      path: routePath.participantMyprofiles,
    },
    {
      icon: 'bi-book',
      name: 'Academy',
      path: routePath.participantAcademy,
    },
  ]

  useEffect(() => {
    if (!isLoggedIn()) {
      return
    }

    if (!userId) {
      getUserDetails(dispatch, userLanguage.userLanguageCode)
    }
  }, [userId])

  // Language useEffects
  useEffect(() => {
    if (!isLoggedIn() || !loggedInUserRole) {
      return
    }

    if (!isParticipant) {
      getFacilitatorLanguages(dispatch).then((response) => {
        if (response) {
          dispatch(setCurrentUserLanguages(response)) // Storing languages in redux
          setLanguageList(response)
        }
      })
    } else {
      getParticipantLanguages(dispatch).then((response) => {
        if (response) {
          dispatch(setCurrentUserLanguages(response)) // Storing languages in redux
          setLanguageList(response)
        }
      })
    }
    getLoggedInUserData(isParticipant, dispatch)
    getGuideData(dispatch)
  }, [loggedInUserRole])

  useEffect(() => {
    if (!isLoggedIn()) {
      return
    }

    if (userLanguage.userLanguageCode && currentUserLanguages.length > 0) {
      const selectedLang =
        currentUserLanguages.find((item) => item.name === userLanguage.userLanguageCode)
          ?.displayName ?? ''
      setSelectedLanguage(selectedLang)
    }
  }, [currentUserLanguages, isLanguageChanged, userLanguage.userLanguageCode])

  useEffect(() => {
    if (!isLoggedIn()) {
      return
    }

    const updatedLanguageList = currentUserLanguages.filter(
      (language) =>
        language.displayName.toLowerCase().includes(languageSearchText.toLowerCase()) ||
        language.name.toLowerCase().includes(languageSearchText.toLowerCase())
    )
    setLanguageList(updatedLanguageList)
  }, [languageSearchText])

  // Left Menu Functions
  const handleLeftMenu = (): void => setOpenLeftMenu(!openLeftMenu)

  // Right Menu Functions
  const handleRightMenu = (): void => {
    setOpenRightMenu(!openRightMenu)
    if (openAccountList) setOpenAccountList(false)
    if (openLanguageList) setOpenLanguageList(false)
    if (acccountSearchText) setAccountSearchText('')
    if (languageSearchText) setLanguageSearchText('')
  }

  const handleAccountList = (): void => setOpenAccountList(!openAccountList)

  const handleSwitchAccount = (account: IClients): void => {
    if (!account.id || !account.name) {
      return
    }

    // TODO: this is incorrect. it's possible to have multiple roles on the same client.
    if (defaultClient.defaultClientId && Number(defaultClient.defaultClientId) === account.id) {
      return
    }

    switchUserRole(account.userRoleId, dispatch).then((res) => {
      dispatch(
        setDefaultClient({
          defaultClientId: account.id,
          defaultClientName: account.name,
        })
      )
      handleAccountList()
      setAccountSearchText('')
      navigate(routePath.home)
      //The page reload reset the account selected and selects back the default account
      //TODO: Once the token scope is fully implemented, we may need the following code.
      //window.location.pathname = routePath.home
    })
  }

  const handleAccountInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setAccountSearchText(e.target.value)
  }

  const handleSettingsClick = (): void => {
    !isParticipant ? navigate(routePath.userProfile) : navigate(routePath.participantUserProfile)
    handleRightMenu()
  }

  const handleRoleChange = (roleName: RoleName): void => {
    const userRole = userDetails.userRoles.find((r) => r.assignedRoleName === roleName)
    switchUserRole(userRole!.userRoleId!, dispatch).then((response) => {
      getUserDetails(dispatch, userLanguage.userLanguageCode).then((details) => {
        authFlow.redirectToStartPageByRole(
          response.accessToken.roleName,
          details,
          MultiRoleBehavior.Ignore
        )
      })
    })
  }

  const handleLanguageList = (): void => setOpenLanguageList(!openLanguageList)

  const handleLanguageInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setLanguageSearchText(e.target.value)
  }

  const closeParticipantLanguageConfirmModal = (): void => {
    setParticipantsLanguageToSelect(undefined)
  }

  const updateParticipantsLanguage = (language: ILanguage): void => {
    dispatch(setSpinner(true))

    const languageCode = language.name
    updateParticipantLanguage(languageCode, dispatch)
      .then((response) => {
        if (response) {
          setSelectedLanguage(language.displayName)
          triggerLanguageChange(languageCode, false, dispatch, language.id)
          closeParticipantLanguageConfirmModal()
        }
      })
      .finally(() => dispatch(setSpinner(false)))
  }

  const handleLanguageSelect = (selectedLanguage: ILanguage): void => {
    if (selectedLanguage.name === userLanguage.userLanguageCode) return

    if (isAdmin || isFacilitator) {
      // Facilitator / Admin Language update
      dispatch(setSpinner(true))

      const body: IUpdateLanguageParams = {
        userId: userId,
        languageCode: selectedLanguage.name,
        isParticipant: isParticipant,
      }
      updateUserLanguage(body, dispatch)
        .then((response) => {
          if (response) {
            triggerLanguageChange(selectedLanguage.name, false, dispatch, selectedLanguage.id)
            handleLanguageList()
            setLanguageSearchText('')
          }
        })
        .finally(() => dispatch(setSpinner(false)))
    } else {
      // Check for any ongoing profiles
      canParticipantChangeLanguageWithoutEffect(dispatch).then((response) => {
        if (response?.success) {
          const noOngoingProfiles: boolean = response.result // True means there are no ongoing forms
          if (noOngoingProfiles) {
            updateParticipantsLanguage(selectedLanguage)
          } else {
            setParticipantsLanguageToSelect(selectedLanguage)
          }
        }
      })
    }
  }

  const handleLogOut = async (): Promise<void> => {
    await logout(dispatch)

    // preserve the user language in the URL after logging out.
    const params = new URLSearchParams({
      [langKey]: userLanguage.userLanguageCode,
    })

    dispatch(resetState())
    window.localStorage.removeItem('logged_in')
    navigate({
      pathname: routePath.signIn,
      search: params.toString(),
    })

    // nocheckin: call logout API
  }

  let topMenuList: IMenu[] = []
  let leftMenuList: IMenu[] = []
  if (isAdmin) {
    topMenuList = adminTopMenuList
    leftMenuList = adminLeftMenuList
  } else if (isFacilitator) {
    topMenuList = facilitatorTopMenuList
    leftMenuList = facilitatorLeftMenuList
  } else if (isParticipant) {
    topMenuList = participantMenuList
    leftMenuList = participantMenuList
  }

  function closeAllMenusImmediately(): void {
    setOpenLeftMenu(false)
    setOpenRightMenu(false)
    setOpenAccountList(false)
    setOpenLanguageList(false)
  }

  function handleBackdropClick(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault()
    event.stopPropagation()

    closeAllMenusImmediately()
  }

  const isAnyMenuOpen = openLeftMenu || openRightMenu || openAccountList || openLanguageList

  // close all menus when the user click the escape key.
  React.useEffect(() => {
    function onEscapeKey(event: KeyboardEvent): void {
      if (isAnyMenuOpen && event.keyCode === KeyCode.Escape) {
        closeAllMenusImmediately()
      }
    }
    window.addEventListener('keydown', onEscapeKey)
    return () => {
      window.removeEventListener('keydown', onEscapeKey)
    }
  }, [isAnyMenuOpen])

  const filteredAccounts = uniqueBy(accounts, (t) => t.id).filter((a) => {
    return (
      defaultClient.defaultClientId === a.id ||
      a.name.toLowerCase().includes(acccountSearchText.toLowerCase())
    )
  })

  return (
    <>
      {/* Top Nav Bar */}
      <nav className='navbar sticky-top navbar-expand-lg bg-white shadow-sm'>
        <div className='container-fluid'>
          <div className='navbar-brand'>
            <div
              className={clsx(
                'border border-secondary btn me-3 text-decoration-none rounded py-1 px-2',
                isParticipant && 'd-lg-none'
              )}
              onClick={handleLeftMenu}
            >
              <i className='bi bi-list fs-5' />
            </div>
            <Link to={isParticipant ? routePath.participantDashboard : routePath.home}>
              <img src={Logo} className='idi-logo' />
            </Link>
          </div>

          <div className='justify-content-between fw-bold d-none d-lg-flex gap-4'>
            {topMenuList &&
              topMenuList.map((menu, idx) => (
                <Link
                  key={idx}
                  to={menu.path}
                  className='text-decoration-none text-dark fw-bold'
                  onClick={() =>
                    isParticipant ? dispatch(clearParticipantProfileDetails()) : null
                  }
                >
                  <i className={clsx('bi pe-2', menu.icon)} />
                  {getLanguageValue(languageText, menu.name)}
                </Link>
              ))}
          </div>

          <div className='border-start border-secondary-light ps-3'>
            <div className='text-decoration-none text-dark' role='button' onClick={handleRightMenu}>
              <span className='badge bg-secondary mb-1'>
                <i className='bi bi-person-fill pe-1' />
                {userDetails.name ? userDetails.name : ''}
              </span>
              <br />
              {isAdmin
                ? getLanguageValue(languageText, 'Administrator')
                : defaultClient.defaultClientName}
            </div>
          </div>
        </div>
      </nav>

      {/* Left Menu */}
      <div
        className={clsx('offcanvas offcanvas-start', openLeftMenu && 'show')}
        id='menu'
        aria-labelledby='offcanvasLeftLabel'
      >
        <div className='offcanvas-header border-bottom border-secondary-light p-4'>
          <h5 className='offcanvas-title' id='offcanvasLeftLabel'>
            {getLanguageValue(languageText, 'Menu')}
          </h5>
          <button type='button' className='btn-close' onClick={handleLeftMenu} />
        </div>
        <div className='offcanvas-body p-0'>
          {leftMenuList &&
            leftMenuList.map((menu, idx) => (
              <div key={idx} className='mb-1 py-2 px-4 border-bottom border-secondary-light'>
                <Link
                  className='text-decoration-none text-dark fw-bold'
                  to={menu.path}
                  onClick={() => {
                    setOpenLeftMenu(false)
                    isParticipant ? dispatch(clearParticipantProfileDetails()) : null
                  }}
                >
                  <i className={clsx('bi pe-2', menu.icon)} />
                  {getLanguageValue(languageText, menu.name)}
                </Link>
              </div>
            ))}
          {isAdmin && (
            <>
              <div className='py-2 px-3 fw-bold'>{getLanguageValue(languageText, 'Settings')}</div>
              {adminLeftMenuSettingsList.map((menu, idx) => (
                <div key={idx} className='mb-1 py-2 px-4 border-bottom border-secondary-light'>
                  <Link
                    className='text-decoration-none text-dark fw-bold'
                    to={menu.path}
                    onClick={() => {
                      setOpenLeftMenu(false)
                    }}
                  >
                    <i className={clsx('bi pe-2', menu.icon)} />
                    {getLanguageValue(languageText, menu.name)}
                  </Link>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      {/* Right Menu */}
      <div className={clsx('offcanvas offcanvas-end', openRightMenu && 'show')} id='setting'>
        <div className='offcanvas-header border-bottom border-secondary-light p-4'>
          <h5 className='offcanvas-title' id='offcanvasRightLabel'>
            {getLanguageValue(languageText, 'Settings')}
          </h5>
          <button type='button' className='btn-close' onClick={handleRightMenu} />
        </div>
        <div className='offcanvas-body p-0'>
          <div className='py-3 px-4 bg-secondary bg-opacity-25'>
            <div className='pb-1 fs-6'>{userDetails.name}</div>
            <div className='fw-bold'>
              {isAdmin
                ? getLanguageValue(languageText, 'Administrator')
                : defaultClient.defaultClientName}
            </div>
          </div>

          {/* Switch Account */}
          {isFacilitator && (
            <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
              <div
                className='text-decoration-none text-dark d-flex justify-content-between'
                role='button'
                onClick={handleAccountList}
              >
                <span className='fw-bold'>
                  <i className='bi bi-building pe-2' />
                  {getLanguageValue(languageText, 'Switch Account')}
                </span>
                <i className='bi bi-chevron-right float-end' />
              </div>
            </div>
          )}

          {/* Account */}
          {(isFacilitator || isParticipant) && (
            <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
              <Link
                className='text-decoration-none text-dark fw-bold'
                to={isFacilitator ? routePath.account : routePath.participantAccountPage}
                onClick={handleRightMenu}
              >
                <i className='bi bi-receipt pe-2' />
                {getLanguageValue(languageText, isFacilitator ? 'Account and billing' : 'Account')}
              </Link>
            </div>
          )}

          {/* Switching Roles */}
          {isFacilitator && userRoles.includes(Roles.Participant) && (
            <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
              <div
                className='text-decoration-none text-dark fw-bold'
                role='button'
                onClick={() => handleRoleChange(Roles.Participant)}
              >
                <i className='bi bi-arrow-repeat pe-2' />
                {getLanguageValue(languageText, 'Switch to participant')}
              </div>
            </div>
          )}
          {isParticipant && userRoles.includes(Roles.Facilitator) && (
            <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
              <div
                className='text-decoration-none text-dark fw-bold'
                role='button'
                onClick={() => handleRoleChange(Roles.Facilitator)}
              >
                <i className='bi bi-arrow-repeat pe-2' />
                {getLanguageValue(languageText, 'Switch to facilitator')}
              </div>
            </div>
          )}

          {/* Settings */}
          <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
            <div
              className='text-decoration-none text-dark fw-bold'
              role='button'
              onClick={handleSettingsClick}
            >
              <i className='bi bi-gear pe-2' />
              {getLanguageValue(languageText, 'Settings')}
            </div>
          </div>

          {/* Language */}
          <div className='mb-1 py-2 px-4 border-top border-secondary-light'>
            <div
              className='text-decoration-none text-dark d-flex justify-content-between'
              role='button'
              onClick={handleLanguageList}
            >
              <span className='fw-bold'>
                <i className='bi bi-globe pe-2' />
                {selectedLanguage}
              </span>
              <i className='bi bi-chevron-right float-end' />
            </div>
          </div>

          {/* LogOut */}
          <div className='mb-1 py-2 px-4 border-top border-bottom border-secondary-light'>
            <div
              className='text-decoration-none text-dark fw-bold'
              role='button'
              onClick={handleLogOut}
            >
              <i className='bi bi-box-arrow-right pe-2' />
              {getLanguageValue(languageText, 'Logout')}
            </div>
          </div>
        </div>
      </div>

      {/* Language List */}
      <div
        className={clsx('offcanvas offcanvas-end', openLanguageList && 'show')}
        id='changelanguage'
      >
        <div className='offcanvas-header border-bottom border-secondary-light p-4'>
          <h5 className='offcanvas-title' id='offcanvasRightLabel'>
            <span className='pe-2' role='button' onClick={handleLanguageList}>
              <i className='bi bi-chevron-left pe-1'></i>
            </span>
            {getLanguageValue(languageText, 'Change language')}
          </h5>
          <button type='button' className='btn-close' onClick={handleRightMenu} />
        </div>
        <div className='offcanvas-body p-0'>
          <div className='p-3 bg-secondary bg-opacity-25'>
            <input
              type='string'
              className='form-control'
              id='languageSearch'
              placeholder={getLanguageValue(languageText, 'Search')}
              value={languageSearchText}
              onChange={handleLanguageInputChange}
            />
          </div>
          {languageList.length > 0 &&
            languageList.map((language) => (
              <div key={language.id} className='mb-1 py-2 px-3 border-top border-secondary-light'>
                <div
                  className='d-inline'
                  role='button'
                  onClick={() => handleLanguageSelect(language)}
                >
                  <i
                    className={clsx(
                      'bi pe-2',
                      language.name === userLanguage.userLanguageCode
                        ? 'bi-check-circle-fill'
                        : 'bi-circle'
                    )}
                  />
                  {language.displayName.split(' ')[0]}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Account List */}
      <div
        className={clsx('offcanvas offcanvas-end', openAccountList && 'show')}
        id='changeaccount'
      >
        <div className='offcanvas-header border-bottom border-secondary-light p-4'>
          <h5 className='offcanvas-title' id='offcanvasRightLabel'>
            <span className='pe-2' role='button' onClick={handleAccountList}>
              <i className='bi bi-chevron-left pe-1'></i>
            </span>
            {getLanguageValue(languageText, 'Switch Account')}
          </h5>
          <button type='button' className='btn-close' onClick={handleRightMenu} />
        </div>
        <div className='offcanvas-body p-0'>
          <div className='p-3 bg-secondary bg-opacity-25'>
            <input
              type='string'
              className='form-control'
              id='accountSearch'
              placeholder={getLanguageValue(languageText, 'Search')}
              value={acccountSearchText}
              onChange={handleAccountInputChange}
            />
          </div>
          {filteredAccounts.map((account, idx) => (
            <div key={idx} className='mb-1 py-2 px-3 border-top border-secondary-light'>
              <div className='d-inline' role='button' onClick={() => handleSwitchAccount(account)}>
                <i
                  className={clsx(
                    'bi pe-2',
                    account.name === defaultClient.defaultClientName
                      ? 'bi-check-circle-fill'
                      : 'bi-circle'
                  )}
                />
                {account.name === defaultClient.defaultClientName && (
                  <i
                    className='bi bi-pin-fill float-end'
                    title={getLanguageValue(languageText, 'Default')}
                  />
                )}
                {account.name}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Language change confirm modal if there are any ongoing profiles */}
      {participantsLanguageToSelect && participantsLanguageToSelect.id > 0 && (
        <CustomModal
          headerText={getLanguageValue(languageText, 'Confirmation message')}
          bodyText={`${getLanguageValue(
            languageText,
            'Filled form data will be cleared. Do you still want to change language'
          )}?`}
          cancelButtonText={getLanguageValue(languageText, 'No')}
          submitButtonText={getLanguageValue(languageText, 'Yes')}
          handleCancelClick={closeParticipantLanguageConfirmModal}
          handleSubmitClick={() => updateParticipantsLanguage(participantsLanguageToSelect)}
        />
      )}

      {(openLeftMenu || openRightMenu) && (
        <div className='offcanvas-backdrop show' onClick={handleBackdropClick} />
      )}
    </>
  )
}

export default NavigationMenu
