import * as React from 'react'

import ParticipantHeaderComponent from '../participantHeaderComponent'
import { useParticipantProfilePage } from './hooks'
import { getLanguageValue } from '../../../../commonUtils/languageFunctionsHelper'
import { getDateWithMonthTimeFormat } from '../../../../commonUtils/dateFunctionsHelper'
import { CoursePresentationStatus } from '@app/containers/profileList/profileEnums'

export const ParticipantProfilePage: React.FC = (props) => {
  const { languageText, presentationData, handleJoinMeeting } = useParticipantProfilePage()

  return (
    <>
      <div className='mt-3 p-2 p-md-4'>
        <div className='d-flex justify-content-between'>
          <ParticipantHeaderComponent currentHeaderId={4} />
        </div>
        <h3 className='mt-4 mt-md-5 mb-4'>{getLanguageValue(languageText, 'Profile')}</h3>

        <div className='rounded shadow-sm bg-white p-4'>
          <h4>{getLanguageValue(languageText, 'Your IDI profile is completed')}.</h4>

          {presentationData.presentationDate ? (
            <>
              <div className='fs-5 my-3'>
                {getLanguageValue(languageText, 'A presentation of your profile is scheduled at')}:
              </div>

              <div className='fs-4 fw-bold mb-3'>
                {getLanguageValue(languageText, presentationData.dayOfWeek)},{' '}
                {getDateWithMonthTimeFormat(presentationData.presentationDate)}
              </div>

              <button
                className='btn btn-success'
                onClick={handleJoinMeeting}
                disabled={presentationData.status !== CoursePresentationStatus.Ongoing}
              >
                {getLanguageValue(languageText, 'Join the meeting')}
              </button>
            </>
          ) : (
            <div className='fs-5 my-3'>
              {getLanguageValue(languageText, 'This presentation no longer exists')}!
            </div>
          )}
        </div>
      </div>
    </>
  )
}
